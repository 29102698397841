import './footer.css'
import {useContext} from "react";
import {GlobalContext} from "../../Context/global";


export function Footer() {
    return (
        <div>
            <div className={"footer"}>
                <div className={"topes"}/>
                <div className={"logo"}>
                    <span className="albe">albe</span><span className="deco">deco</span>
                </div>
                <div className={"direccion"}>
                    <p> P.I. Pla de Sant Vicent · Camí Tarongers, 6</p>

                    <p> 46870 Ontinyent (Valencia) SPAIN - P.O. BOX, 317</p>

                    <p> Tel. +34 962 369 435</p>

                    <p> E-mail: albedeco@albedeco.com</p>
                </div>
                <div className={"social-links"}>
                    <div className="socialmedia">
                        <a href="https://www.facebook.com/profile.php?id=100072908450706" target="_blank">
                            <img src={"images/facebook.svg"}
                                 alt="facebook"/>
                        </a>
                        <a href="https://www.instagram.com/albedeco/" target="_blank">
                            <img src={"images/instagram.svg"} alt="instagram"/>
                        </a>
                        <a href="" target="_blank">
                            <img src={"images/linkedin.svg"} alt="linkedin"/>
                        </a>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    );
}

const Copyright = () => {
    const [context] = useContext(GlobalContext)
    const titles = context.titles()

    return (
        <div className={"copyright"}>
            <span>{titles.copyright}</span>
        </div>
    )
}