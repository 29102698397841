import './App.css';
import {Menu} from "./Components/menu/menu";
import {Route, Routes, useLocation} from "react-router-dom";
import {About} from "./Components/about";
import {Weare} from "./Components/weare";
import {Showroom} from "./Components/showroom";
import {Contact} from "./Components/contact";
import React, {useEffect, useState} from "react";
import {Footer} from "./Components/footer/footer";
import {Home} from "./Components/home/home";
import {GlobalContext, initialContext, updater} from "./Context/global";
import {Clients} from "./Components/clients";

function App() {
    const [state, setState] = useState(initialContext)
    return (
        <GlobalContext.Provider value={[state, updater(state, setState)]}>
            <div className="App">
                <ScrollToTop />
                <Menu/>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="weare" element={<Weare/>}>
                        <Route path=":product" element={<Weare/>}/>
                    </Route>
                    <Route path="clients" element={<Clients/>}/>
                    <Route path="showroom" element={<Showroom/>}/>
                    <Route path="contact" element={<Contact/>}/>
                </Routes>
                <Footer/>
            </div>
        </GlobalContext.Provider>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default App;
