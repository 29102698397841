import {Link, useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import './menu.css'
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../Context/global";
import useWindowDimensions from "../../hooks/useWidth";

export const Menu = () => {
    const [context] = useContext(GlobalContext)
    const { width } = useWindowDimensions();
    const [collapse, setCollapse] = useState(false)
    useEffect(() => {
        if (width < 1024) {
            setCollapse(false)
        }
    }, [width])

    const CollapseMenu = () => {
        setCollapse(false)
    }

    const titles = context.titles()
    let navigate = useNavigate();
    return (
        <nav className={`menu ${collapse ? "full" : ""}`}>
            <div className={"topes"}/>
            <div className="logo" onClick={() => navigate('/')}>
                <span className="albe">albe</span><span className="deco">deco</span>
            </div>
            <div className={"links"}>
                <Link to="/" onClick={() => CollapseMenu()} >{titles.home}</Link>
                <Link to="weare" onClick={() => CollapseMenu()} >{titles.weare}</Link>
                <Link to="about" onClick={() => CollapseMenu()} > {titles.about}</Link>
                <Link to="clients" onClick={() => CollapseMenu()} >{titles.clients}</Link>
                <Link to="contact" onClick={() => CollapseMenu()} >{titles.contact}</Link>
            </div>
            <LangSelector />
            <div className={"topes"}/>
            <div className={"hamburg"} onClick={() => {
                setCollapse(old => !old)

            }}>{
                !collapse ?
                    <MenuIcon />
                 : <MenuOpenIcon />
            }
            </div>

        </nav>
    )
}

const LangSelector = () => {
    const [context, {changeLang}] = useContext(GlobalContext)
    const handleChange = (event) => {
        const lang = event.target.value
        changeLang(lang)
    }
    return (
        <div className="lang-selector">
            <select name="lang" id="lang" onChange={handleChange} value={context.lang} >
                <option value="es">ES</option>
                <option value="en">EN</option>
                <option value="fr">FR</option>
            </select>
        </div>
    )
}