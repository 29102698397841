import './weare.css'
import {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../Context/global";
import digitalCenter from "../Assets/images/productos/DIGITAL MANTEL.jpg"
import digitalRight from "../Assets/images/productos/DIGITAL CAMA GRANDE-TIRA.jpg"
import digitalLeft from "../Assets/images/productos/DIGITAL CAMA INFANTIL-GALLINETES.jpg"
import lisosCenter from "../Assets/images/productos/LISOS SOFA.jpg"
import lisosRight from "../Assets/images/productos/LISOS COJINES.jpg"
import lisosLeft from "../Assets/images/productos/LISOS ROLLOS.jpg"
import confRight from "../Assets/images/productos/CONFECCION CAMA.jpg"
import confCenter from "../Assets/images/productos/CONFECCION CORTINA.jpg"
import confLeft from "../Assets/images/productos/CONFECCION MANTEL.jpg"
import jacCenter from "../Assets/images/productos/JACQUARD COJIN.jpg"
import jacLeft from "../Assets/images/productos/JACQUARD COJINES.jpg"
import jacRight from "../Assets/images/productos/JACQUARD SOFA.jpg"
import {useParams} from "react-router-dom";
import front from "../Assets/images/PRODUCTOS.jpg";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useWindowDimensions from "../hooks/useWidth";

export const Weare = () => {
    const [context] = useContext(GlobalContext)
    const titles = context.titles()
    const {product} = useParams()
    const product1 = useRef(null)
    const product2 = useRef(null)
    const product3 = useRef(null)
    const product4 = useRef(null)

    useEffect(() => {
        switch (product) {
            case "digital":
                product1.current.scrollIntoView()
                break;
            case "jaq":
                product2.current.scrollIntoView()
                break;
            case "conf":
                product3.current.scrollIntoView()
                break;
            case "lisos":
                product4.current.scrollIntoView()
                break;
        }
    })
    return (
        <div className={"weare"}>
            <div className={"front"}>
                <img src={front} alt={"paleta"}/>
            </div>
            <div className={"seccion "}>
                <div className={"texto cabecera item-12"}>
                    {titles.productPresentation.map(part => <p key={Math.random()} className={"text"}>{part}</p>)}
                </div>
            </div>
            <div ref={product1} className={"container"}>
                <Product titles={titles.product1}
                         images={{left: digitalLeft, center: digitalCenter, right: digitalRight}}/>

            </div>
            <div ref={product2} className={"container"}>
                <Product titles={titles.product2} images={{left: jacLeft, center: jacCenter, right: jacRight}}/>

            </div>
            <div ref={product3} className={"container"}>
                <Product titles={titles.product3} images={{left: confLeft, center: confCenter, right: confRight}}/>

            </div>
            <div ref={product4} className={"container"}>
                <Product titles={titles.product4} images={{left: lisosLeft, center: lisosCenter, right: lisosRight}}/>

            </div>
        </div>
    )
}

function Product({titles, images}) {
    const [columns, setColumns] = useState(3)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width < 1280) {
            setColumns(1)
        } else {
            setColumns(3)
        }
    }, [width])

    return (
        <div className={"product-container"}>
            <div className={"title"}>{titles.title}</div>
            <div className={"paddingContainer"}>{
                titles.text.map(part => <p key={Math.random()} className={"text"}>{part}</p>)
            }</div>
            <ImageList cols={columns} gap={100}>
                    <ImageListItem >
                        <img
                            src={images.left}
                            alt={""}
                            loading="lazy"
                        />
                    </ImageListItem>
                    <ImageListItem >
                        <img
                            src={images.center}
                            alt={""}
                            loading="lazy"
                        />
                    </ImageListItem>
                    <ImageListItem >
                        <img
                            src={images.right}
                            alt={""}
                            loading="lazy"
                        />
                    </ImageListItem>

            </ImageList>
            {/*<div className={"images"}>*/}
                {/*<img src={images.left}/>*/}
                {/*<img src={images.center}/>*/}
                {/*<img src={images.right}/>*/}
            {/*</div>*/}
        </div>
    )
}