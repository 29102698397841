import {createContext} from "react";
import {espanol} from "../Assets/traducciones/es";
import {english} from "../Assets/traducciones/en";
import {francais} from "../Assets/traducciones/fr";

const langs = {
    es: espanol,
    en: english,
    fr: francais
}

const initialContext = {
    lang : "es",
    titles : function () {
        return menuState(this.lang)
    },
}


const menuState = (lang) => {
    return ({
        ...langs[lang]
    })
}

const updater = (state, setter) => ({
    changeLang : (lang) => setter({...state, lang})
})

const GlobalContext = createContext([initialContext, () => {}]);

export {
    GlobalContext,
    initialContext,
    updater
};