import {useContext} from "react";
import {GlobalContext} from "../Context/global";
import front from "../Assets/images/COCOCENOS.jpg";


export const About = () => {
    const [context] = useContext(GlobalContext)
    const titles = context.titles()
    return (

        <div className={"about"}>
            <div className={"front"}>
                <img src={front} alt={"paleta"}/>
            </div>
            <div className={"seccion "}>
                <div className={"texto cabecera item-12"}>
                    {titles.aboutPresentation.map(part => <p key={Math.random()} className={"text"}>{part}</p>)}
                </div>
            </div>
        </div>
    )
}