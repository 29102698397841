import {SeccionLeft} from "./home/home";
import './showroom.css'

export const Showroom = () => {
    return (
        <div className={"showroom"}>
            <section>
                {/*<SeccionLeft ></SeccionLeft>*/}
            </section>
        </div>
    )
}