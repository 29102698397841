import Conciliacion from "./compro-icons/conciliacion";
import Igualdad from "./compro-icons/igualdad"
import Integracion from "./compro-icons/integracion"
import Reciclaje from "./compro-icons/reciclaje"
import Sostenibilida from "./compro-icons/sostenibilidad"
import "./compromisos.css"
import {useContext} from "react";
import {GlobalContext} from "../../Context/global";

export const Compromisos = () => {
    const [context] = useContext(GlobalContext)
    const titles = context.titles()
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 30,
                paddingBottom: 15,
                color: "#8c8c8c"
            }}>NUESTROS COMPROMISOS
            </div>
            <div className={"container"}>
                <Compromiso ConLogo={Conciliacion} text={titles.compromisos.conciliacion}/>
                <Compromiso ConLogo={Igualdad} text={titles.compromisos.igualdad}/>
                <Compromiso ConLogo={Integracion} text={titles.compromisos.integracion}/>
                <Compromiso ConLogo={Reciclaje} text={titles.compromisos.reciclaje}/>
                <Compromiso ConLogo={Sostenibilida} text={titles.compromisos.sostenibilidad}/>
            </div>
        </div>

    )
}

const Compromiso = ({ConLogo, text}) => {
    return (
        <div className={"item"}>
            <div className={"image"}>
                <ConLogo/>
            </div>
            <p>{text.title}</p>
            <div className={"text"}>{text.text}</div>
        </div>
    )
}