export const english = {
    home: "Home",
    about: "About us",
    weare: "Our jobs",
    clients: "Clients",
    showroom: "Showroom",
    contact: "Contact",
    copyright: "COPYRIGHT 2022 ALBEDECO. ALL RIGHTS RESERVED",
    contactForm: {
        name: "Enter your name",
        surname: "Enter your surname",
        email: "Enter your mail",
        telf: "Enter your phone",
        subject: "Enter the subject",
        content: "Enter the content",
        send: "Send",
        privacidad: "I accept privacy policy"
    },
    contactHeader: {
        title: "How can we help you?",
        desc: "You can contact us through the following form"
    },
    compromisos: {
        conciliacion: {
            text: "Intensive hours to favor the reconciliation of work and family",
            title: "Conciliation"
        },
        igualdad: {
            text: "Salary equality among employees, and majority of women in managerial positions",
            title: "Equality"
        },
        integracion: {
            text: "Workers with functional diversity in the workforce",
            title: "Social Integration"
        },
        reciclaje: {
            text: "Waste separation system in our offices and warehouses",
            title: "Recycling"
        },
        sostenibilidad: {
            text: "Recognition of our workers for using sustainable transport",
            title: "Sustainability"
        },
    },
    homePresentation: ["Albedeco is a young company born in Valencia, Spain, marked by the Mediterranean\n" +
    "character that characterizes us and influenced by our constant travels around the world.\n" +
    "Our international vocation characterizes us.",
    "We are made up of a management team and young professionals, but at the same time\n" +
    "with great experience in the sector, always oriented to the satisfaction of our clients.",
    "Our products are made following strict quality controls, with current and innovative\n" +
    "designs made with the best materials, which give our fabrics the quality that\n" +
    "characterizes us.",
    "Quality and customer satisfaction are premises that mark our brand culture."],
    clientsPresentation: [`At Albedeco we open the borders to the international market, with representatives in the
main cities of Europe. Our clear international vocation focuses us on being present in
more and more countries and our growth objective has in mind a clearly exporting
mentality.`,`We are committed to manufacturing as much as possible in our country. We are proud
of the land to which we belong and of our roots, proud of being ‘Made in Spain’,
promoting the local and proximity economy and reducing the carbon footprint to the
minimum.`, `In turn, the great influences that we receive from our international trips and the
proposals and suggestions of our clients, make us develop designs and collections
thinking about the markets to which our articles are going to be destined. Our premise is
to offer our customers what they want and need.`, `We conceive, develop, create and manufacture products with you, our customer, in mind.
Your satisfaction is our best reward!`],
    aboutPresentation: ["Albedeco is a young company born in Valencia, Spain, marked by the Mediterranean\n" +
    "character that characterizes us and influenced by our constant travels around the world.\n" +
    "Our international vocation characterizes us.",
        "We are made up of a management team and young professionals, but at the same time\n" +
        "with great experience in the sector, always oriented to the satisfaction of our clients.",
        "Our products are made following strict quality controls, with current and innovative\n" +
        "designs made with the best materials, which give our fabrics the quality that\n" +
        "characterizes us.",
        "Quality and customer satisfaction are premises that mark our brand culture."],
    productPresentation: [`Our products are created from the beginning with love, respect for the environment and
quality.`,
        `Love for beauty, for design and for the work we do. Passion for fashion, for trendy
designs and colours. Passion for decoration!`,
        `Respect for nature, the environment and the people involved in the creation,
development and production of our articles.`, `Quality, as a fundamental premise of our creations. All our products are produced
following strict quality parameters, fair working conditions and close collaboration with
our suppliers, which allows us that from the beginning to the end that all the people
involved in the process give our best to offer the best of ourselves.`
    ],
    home1: {
        title: "DIGITAL PRINT",
        text: [`In Albedeco we are specialized in the manufacture of high quality fabrics mainly
intended for decoration and fashion.`,`Digital printing allows us to follow the latest market trends in terms of design and color,
while allowing us to use different materials that result in offering a multitude of different
fabrics.`,`Our internal design team, together with the collaboration of free-lance designers from
various countries, is in charge of creating and developing unique collections adapted to
all tastes and markets, always betting on design and sustainability.`,`Design to power!`]
    },
    home2: {
        title: "JACQUARDS",
        text: [`We have extensive experience in the manufacture of exclusive Jacquard fabrics for big
customers, offering the possibility of manufacturing exclusive designs on demand.`,`We have a team of professionals with large experience in the creation of Jacquard
fabrics who contribute to the searching and development of new fabrics and structures,
thus creating inspiring collections.`,`Our maxim is to create exclusive and quality items that satisfy our customers.`]
    },
    home3: {
        title: "READY-MADE ARTICLES",
        text: [`At Albedeco we have a wide range of ready-made items for home decoration. Our Home
is the environment in which we spend the greatest number of hours of our lives and
therefore it should be the place where we are most comfortable. That is why we are so
concerned about creating products that make the decoration of our home make us fell
happy. We love our home!`,`All of us who make up Albedeco are aware of the importance that home and decoration
have in our lives and we care about creating exclusive designs in a responsible and
efficient manner, betting on Made in Spain manufacturing, giving our customers the
products they deserve.`]
    },
    home4: {
        title: "PLAIN FABRICS",
        text: [`We have a wide range of plain fabrics in different qualities and finishes, with a wide
range of colours.`,`Each fabric and color is perfectly studied and selected following the current trends of
each moment.`,`We will be glad to advise you to meet the needs and specifications you are looking for.`]
    },
    product1: {
        title: "DIGITAL PRINT",
        text: [`In Albedeco we are specialized in the manufacture of high quality fabrics mainly
intended for decoration and fashion.`,`Digital printing allows us to follow the latest market trends in terms of design and color,
while allowing us to use different materials that result in offering a multitude of different
fabrics.`,`Our internal design team, together with the collaboration of free-lance designers from
various countries, is in charge of creating and developing unique collections adapted to
all tastes and markets, always betting on design and sustainability.`,`Design to power!`]
    },
    product4: {
        title: "PLAIN FABRICS",
        text: [`We have a wide range of plain fabrics in different qualities and finishes, with a wide
range of colours.`,`Each fabric and color is perfectly studied and selected following the current trends of
each moment.`,`We will be glad to advise you to meet the needs and specifications you are looking for.`]
    },
    product2: {
        title: "JACQUARDS",
        text: [`We have extensive experience in the manufacture of exclusive Jacquard fabrics for big
customers, offering the possibility of manufacturing exclusive designs on demand.`,`We have a team of professionals with large experience in the creation of Jacquard
fabrics who contribute to the searching and development of new fabrics and structures,
thus creating inspiring collections.`,`Our maxim is to create exclusive and quality items that satisfy our customers.`]
    },
    product3: {
        title: "READY-MADE ARTICLES",
        text: [`At Albedeco we have a wide range of ready-made items for home decoration. Our Home
is the environment in which we spend the greatest number of hours of our lives and
therefore it should be the place where we are most comfortable. That is why we are so
concerned about creating products that make the decoration of our home make us fell
happy. We love our home!`,`All of us who make up Albedeco are aware of the importance that home and decoration
have in our lives and we care about creating exclusive designs in a responsible and
efficient manner, betting on Made in Spain manufacturing, giving our customers the
products they deserve.`]
    },
    mailExito: "Mail sended successfully",
    mailError: "The email could not be sent",
}