export const espanol = {
    home: "Inicio",
    about: "Nosotros",
    weare: "Productos",
    clients: "Clientes",
    showroom: "Showroom",
    contact: "Contacto",
    copyright: "COPYRIGHT 2022 ALBEDECO. TODOS LOS DERECHOS RESERVADOS",
    contactForm: {
        name: "Introduce tu nombre",
        surname: "Introduce tus apellidos",
        email: "Introduce tu email",
        telf: "Introduce tu teléfono",
        subject: "Introduce el asunto",
        content: "Introduce tu mensaje",
        send: "Enviar",
        privacidad: "Acepto política de privacidad"
    },
    contactHeader: {
        title: "¿En qué podemos ayudarte?",
        desc: "Puedes contactar con nosotros a través del siguiente formulario"
    },
    compromisos: {
        conciliacion: {
            text: "Horario intensivo para favorecer la conciliación laboral y familiar",
            title: "Conciliación"
        },
        igualdad: {
            text: "Igualdad salarial entre empleados, y mayoría de mujeres en puestos directivos",
            title: "Igualdad"
        },
        integracion: {
            text: "Trabajadores con diversidad funcional en plantilla",
            title: "Integración"
        },
        reciclaje: {
            text: "Sistema de separación de residuos en nuestras oficinas y almacenes",
            title: "Reciclaje"
        },
        sostenibilidad: {
            text: "Reconocimiento a nuestros trabajadores por utilizar transporte sostenible",
            title: "Sostenibilidad"
        },
    },
    homePresentation: ["Albedeco es una empresa joven de textil hogar nacida en España, con un equipo directivo y de profesionales con gran experiencia en el sector.\n" +
    "\n" +
    "                    Sus productos son elaborados siguiendo un estricto control de calidad, con diseños actuales e innovadores, fabricados en las mejores telas y tejidos.\n" +
    "\n" +
    "                    Siempre orientados a dar el mejor servicio a sus clientes."],
    clientsPresentation: [`En Albedeco abrimos las fronteras al mercado internacional, con representantes en las
principales ciudades de Europa. Nuestra clara vocación internacional nos enfoca a estar
presentes en cada vez más países y nuestro objetivo de crecimiento tiene presente una
mentalidad claramente exportadora.`,`Apostamos por la fabricación en todo lo posible en nuestro país. Estamos orgullosos de
la tierra a la que pertenecemos y de nuestras raíces, orgullosos de ser “Made in Spain”,
fomentando la economía local y de proximidad y reduciendo la huella de carbono al
mínimo.`, `A su vez, las grandes influencias que recibimos de nuestros viajes internacionales y las
propuestas y sugerencias de nuestros clientes, hacen que desarrollemos diseños y
colecciones pensando en los mercados a los que nuestros artículos van a ir destinados.
Nuestra premisa es ofrecer al cliente lo que éste quiere y necesita.`],
    aboutPresentation: [`Albedeco es una empresa joven nacida en Valencia, España, marcada por el carácter
mediterráneo que nos caracteriza e influenciada por nuestros constantes viajes por todo
el mundo. Nuestra vocación internacional nos caracteriza.`, `La formamos un equipo directivo y de profesionales joven, pero a su vez con gran
experiencia en el sector, siempre orientados a la satisfacción de nuestros clientes.`, `Nuestros productos son elaborados siguiendo un estricto control de calidad, con
diseños actuales e innovadores fabricados con los mejores materiales, lo que otorgan a
nuestros tejidos la calidad que nos caracteriza.`],
    productPresentation: [`Nuestros productos son creados desde su inicio con amor, respeto por el medio
ambiente y calidad.`,
        `Amor por la belleza, por el diseño y por el trabajo que realizamos. Pasión por la moda,
por los diseños y coloridos de tendencia. ¡Pasión por la decoración!`, `Respeto por la naturaleza, el medio ambiente y las personas involucradas en la creación,
desarrollo y producción de nuestros artículos.`,
        `Calidad, como premisa fundamental de nuestras creaciones. Todos nuestros productos
se producen siguiendo unos estrictos parámetros de calidad, condiciones de trabajo
justas y una estrecha colaboración con nuestros proveedores, lo que nos permite que
desde el inicio hasta el final todas las personas involucradas en el proceso demos el
máximo para ofrecer lo mejor de nosotros mismos.`
    ],
    home1: {
        title: "DIGITAL",
        text: [`En Albedeco estamos especializados en la fabricación de tejidos de alta calidad
destinados principalmente a la decoración y la moda.`,`
La estampación digital nos permite seguir las últimas tendencias del mercado en cuanto
a diseño y colorido, permitiéndonos a su vez el uso de diferentes materiales que dan
como resultado el ofrecer multitud de tejidos diferentes.`,`
Nuestro equipo de diseño interno junto a la colaboración de diseñadores free-lance de
diversos países, se encarga de crear y desarrollar colecciones únicas adaptadas a todos
los gustos y mercados, apostando siempre por el diseño y la sostenibilidad.`,`
¡El diseño al poder!`]
    },
    home2: {
        title: "JACQUARD",
        text: [`Gozamos de una amplia experiencia en la fabricación de tejidos Jacquard exclusivos
para clientes importantes, ofreciendo la posibilidad de fabricar diseños exclusivos bajo
demanda.`,`
Contamos con un equipo de profesionales con una larga trayectoria en la creación de
tejidos Jacquard que contribuyen a la búsqueda y desarrollo de nuevos tejidos y
estructuras, creando así colecciones inspiradoras.`,`
Nuestra máxima es crear artículos exclusivos y de calidad que satisfagan a nuestros
clientes.`]
    },
    home3: {
        title: "CONFECCIÓN",
        text: [`En Albedeco disponemos de una amplia gama de artículos confeccionados para la
decoración del hogar. El hogar es el entorno en el que pasamos el mayor número de
horas de nuestra vida y por tanto debe ser el lugar en el que más a gusto nos
encontremos. Por ello nos preocupa tanto crear productos que hagan que la decoración
de nuestro hogar nos haga sentir felices. ¡Amamos nuestra casa!`,`
Todos los que formamos Albedeco somos conscientes de la importancia que el hogar y
la decoración tienen en nuestras vidas y nos preocupamos por crear diseños exclusivos
de manera responsable y eficiente, apostando por la fabricación Made in Spain, dando a
nuestros clientes los productos que merecen.`,` 
Concebimos, desarrollamos, creamos y fabricamos productos pensando en ti, nuestro
cliente.`, `¡Tú satisfacción es nuestra mejor recompensa!`]
    },
    home4: {
        title: "LISOS",
        text: [`Disponemos de una amplia gama de tejidos lisos en diferentes calidades y acabados y
con amplios coloridos. Cada tejido y color está perfectamente estudiado y seleccionado siguiendo las
tendencias actuales del momento.`,`
Estaremos encantados de asesorarte para cumplir las necesidades y especificaciones
que estás buscando.`]
    },
    product1: {
        title: "ESTAMPACIÓN DIGITAL",
        text: [`En Albedeco estamos especializados en la fabricación de tejidos de alta calidad
destinados principalmente a la decoración y la moda.`,`
La estampación digital nos permite seguir las últimas tendencias del mercado en cuanto
a diseño y colorido, permitiéndonos a su vez el uso de diferentes materiales que dan
como resultado el ofrecer multitud de tejidos diferentes.`,`
Nuestro equipo de diseño interno junto a la colaboración de diseñadores free-lance de
diversos países, se encarga de crear y desarrollar colecciones únicas adaptadas a todos
los gustos y mercados, apostando siempre por el diseño y la sostenibilidad.`,`
¡El diseño al poder!`]
    },
    product4: {
        title: "LISOS",
        text: [`Disponemos de una amplia gama de tejidos lisos en diferentes calidades y acabados y
con amplios coloridos.`,
`Cada tejido y color está perfectamente estudiado y seleccionado siguiendo las
tendencias actuales del momento.`,
`Estaremos encantados de asesorarte para cumplir las necesidades y especificaciones
que estás buscando.`]
    },
    product2: {
        title: "JACQUARD",
        text: [`Gozamos de una amplia experiencia en la fabricación de tejidos Jacquard exclusivos
para clientes importantes, ofreciendo la posibilidad de fabricar diseños exclusivos bajo
demanda.`,`
Contamos con un equipo de profesionales con una larga trayectoria en la creación de
tejidos Jacquard que contribuyen a la búsqueda y desarrollo de nuevos tejidos y
estructuras, creando así colecciones inspiradoras.`,`
Nuestra máxima es crear artículos exclusivos y de calidad que satisfagan a nuestros
clientes.`]
    },
    product3: {
        title: "PRODUCTO CONFECCIONADO",
        text: [`En Albedeco disponemos de una amplia gama de artículos confeccionados para la
decoración del hogar. El hogar es el entorno en el que pasamos el mayor número de
horas de nuestra vida y por tanto debe ser el lugar en el que más a gusto nos
encontremos. Por ello nos preocupa tanto crear productos que hagan que la decoración
de nuestro hogar nos haga sentir felices. ¡Amamos nuestra casa!`,`
Todos los que formamos Albedeco somos conscientes de la importancia que el hogar y
la decoración tienen en nuestras vidas y nos preocupamos por crear diseños exclusivos
de manera responsable y eficiente, apostando por la fabricación Made in Spain, dando a
nuestros clientes los productos que merecen.`,` 
Concebimos, desarrollamos, creamos y fabricamos productos pensando en ti, nuestro
cliente.`, `¡Tú satisfacción es nuestra mejor recompensa!`]
    },
    mailExito: "Email enviado correctamente",
    mailError: "No se ha podido enviar el email",
}