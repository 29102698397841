import React from "react";

function Sostenibilidad() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="-348.503 986.556 538.756 491.907"
            xmlSpace="preserve"
        >
            <g>
                <g transform="translate(-5.236 -226.819)">
                    <g transform="matrix(.6319 0 0 .6319 -16.002 -89.951) rotate(-120 129.132 571.793)">
                        <g>
                            <path d="M-2044.75 526.469l-128.023-1.275-59.771 103.523a22.52 22.52 0 00-.991 1.931 22.327 22.327 0 00-1.855 6.507 22.38 22.38 0 00-.174 2.255c-.022.755-.006 1.511.047 2.264a22.613 22.613 0 001.498 6.606c.274.702.583 1.391.926 2.062l.037.084a22.691 22.691 0 002.472 3.786 22.74 22.74 0 003.171 3.227 22.574 22.574 0 005.797 3.5c.696.291 1.406.546 2.128.766a22.672 22.672 0 006.549.968h.005l168.185 1.275-.001-137.479z"></path>
                            <path d="M-2123.936 510.359l-56.047-143.456-152.26 23.19 44.623 25.763-44.133 76.449a21.95 21.95 0 00-1.028 2.016 21.882 21.882 0 00-.825 2.106 22.58 22.58 0 00-.613 2.179 23.42 23.42 0 00-.389 2.233 22.648 22.648 0 00-.167 2.253c-.018.756 0 1.515.054 2.268a23.04 23.04 0 00.795 4.447 22.606 22.606 0 001.662 4.205c.346.671.723 1.329 1.135 1.962l78.196 135.44a22.55 22.55 0 01-.924-2.064 22.924 22.924 0 01-.721-2.152 22.612 22.612 0 01-.774-4.456 20.84 20.84 0 01-.046-2.263 22.47 22.47 0 01.574-4.481c.17-.735.375-1.467.619-2.181.244-.715.524-1.411.837-2.098.297-.66.628-1.303.99-1.93l59.771-103.527 24.049-41.664 44.622 25.761z"></path>
                        </g>
                        <g>
                            <path d="M-1945.109 395.784l62.008 112.011 119.535.964c.724.005 1.448-.025 2.168-.089a22.517 22.517 0 004.441-.843 22.572 22.572 0 004.182-1.71 22.57 22.57 0 001.947-1.157 22.764 22.764 0 003.493-2.87 22.662 22.662 0 001.515-1.69c.476-.585.922-1.193 1.338-1.822a3.53 3.53 0 01.055-.074 22.875 22.875 0 002.806-6.159 22.577 22.577 0 00.701-8.977 22.414 22.414 0 00-.383-2.229 22.514 22.514 0 00-1.433-4.29 22.471 22.471 0 00-.956-1.888l-81.809-146.953-119.608 67.776z"></path>
                            <path d="M-1921.854 472.974l-97.177 119.488 95.242 121.036.414-51.525 88.27.705a22.17 22.17 0 002.261-.099 22.659 22.659 0 004.437-.861 23.335 23.335 0 002.134-.762 22.52 22.52 0 002.042-.966 23.624 23.624 0 001.947-1.166 22.937 22.937 0 004.989-4.577c.474-.588.914-1.195 1.325-1.826.413-.632.8-1.284 1.148-1.955l79.283-134.807a22.67 22.67 0 01-1.34 1.821 22.66 22.66 0 01-3.192 3.211c-.583.48-1.192.928-1.82 1.348-.628.419-1.277.807-1.947 1.155a22.281 22.281 0 01-4.181 1.709c-.724.214-1.461.397-2.203.537-.742.14-1.486.24-2.238.306-.721.067-1.443.096-2.167.091l-119.538-.962-48.105-.382.416-51.519z"></path>
                        </g>
                        <g>
                            <path d="M-2107.412 378.419l64.087-110.834-60.728-102.964a22.656 22.656 0 00-1.193-1.813 22.433 22.433 0 00-3.01-3.373 22.623 22.623 0 00-3.62-2.704 22.503 22.503 0 00-1.995-1.073 22.78 22.78 0 00-4.259-1.516 22.762 22.762 0 00-2.229-.429 22.63 22.63 0 00-2.251-.209l-.092-.009a22.478 22.478 0 00-2.263.033 22.624 22.624 0 00-4.461.733 22.688 22.688 0 00-6.207 2.714c-.638.405-1.256.841-1.85 1.307-.596.463-1.167.956-1.714 1.476a22.74 22.74 0 00-2.941 3.438 22.819 22.819 0 00-1.124 1.788l-.002.004-83.844 145.801 119.696 67.63z"></path>
                            <path d="M-2053.981 316.26l152.469 21.772 54.71-143.971-44.381 26.177-44.85-76.03a21.837 21.837 0 00-1.249-1.887 22.599 22.599 0 00-3.023-3.361 23.196 23.196 0 00-1.753-1.437 23.41 23.41 0 00-3.882-2.321 23.01 23.01 0 00-4.262-1.495 22.767 22.767 0 00-2.23-.425 22.6 22.6 0 00-2.248-.196 22.859 22.859 0 00-2.267.022l-156.386 1.455c.754.032 1.504.103 2.251.21a22.59 22.59 0 014.397 1.083c.711.253 1.409.544 2.092.866.682.323 1.35.679 1.993 1.074.649.388 1.271.812 1.878 1.259.604.452 1.184.933 1.74 1.444.556.51 1.093 1.049 1.594 1.612.502.564.971 1.15 1.415 1.761.428.583.825 1.188 1.194 1.811l60.732 102.966 24.444 41.433-44.378 26.178z"></path>
                        </g>
                    </g>
                </g>
            </g>
            <g transform="translate(-468.492 218.38) scale(1.41865)">
                <path d="M212.768 776.237c21.444 37.192 65.2 57.422 113.767 43.111 13.21 2.005 26.834 4.566 36.827 2.293 3.477-4.337 1.594-6.701-.037-9.909-51.612 7.753-92.253-45.726-112.506-81.612 24.821 31.585 53.865 57.033 87.551 70.646 34.716-101.424-78.974-87.789-84.957-192.738-60.511 60.575-65.258 125.535-40.646 168.208l.001.001z"></path>
            </g>
        </svg>
    );
}

export default Sostenibilidad;