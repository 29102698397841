import './home.css'
import front from '../../Assets/images/principal9.jpeg'
import digital from '../../Assets/images/DIGITAL.jpg'
import conf from '../../Assets/images/CONFECCION.jpg'
import lisos from '../../Assets/images/LISOS.jpg'
import jaq from '../../Assets/images/JACQUARD.jpg'
import {GlobalContext} from "../../Context/global";
import {useContext} from "react";
import {Compromisos} from "../footer/compromisos";
import {useNavigate} from "react-router-dom";

export function Home() {
    const [context] = useContext(GlobalContext)
    const titles = context.titles()
    let navigate = useNavigate();

    const onNavigate = (product) => {
        navigate(`/weare/${product}`, {replace: true})
    }
    return (
        <div className={"home"}>
            <div className={"front"}>
                <img src={front} alt={"paleta"}/>
            </div>
            <div className={"seccion "}>
                <div className={"texto cabecera item-12"}>
                    {/*{titles.homePresentation}*/}
                    {titles.homePresentation.map(part => <p key={Math.random()} className={"text"}>{part}</p>)}
                </div>
            </div>
            <section onClick={() => onNavigate("digital")}>
                <SeccionLeft img={digital} text={titles.home1}/>
            </section>
            <section onClick={() => onNavigate("jaq")}>
                <SeccionRight img={jaq} text={titles.home2}/>
            </section>
            <section onClick={() => onNavigate("conf")}>
                <SeccionLeft img={conf} text={titles.home3}/>
            </section>
            <section onClick={() => onNavigate("lisos")}>
                <SeccionRight img={lisos} text={titles.home4}/>
            </section>
            <Compromisos/>
        </div>
    )
        ;
}

function SeccionLeft({img, text}) {
    console.log(text)
    return (
        <div className={"seccion left"}>
            <div className={"image-container item-6"}>
                {/*<div className={"carrousel"}>*/}

                <img className={"image"} src={img}/>
                {/*<img className={"image pos-2"} src={img}/>*/}
                {/*</div>*/}
                {/*<div className={"tooltip"}>DIGITAL</div>*/}
            </div>
            <div className={"texto-container item-6"}>
                <div className={"texto "}>
                    <div className={"title tooltip"}>{text.title}</div>
                    {text.text.map(part => <p key={Math.random()} className={"text"}>{part}</p>)}
                </div>
            </div>

        </div>)
}

function SeccionRight({img, text}) {
    return (
        <div className={"seccion right"}>
            <div className={"texto-container  item-6"}>
                <div className={"texto "}>
                    <div className={"title tooltip"}>{text.title}</div>
                    {text.text.map(part => <p key={Math.random()} className={"text"}>{part}</p>)}
                </div>
            </div>
            <div className={"image-container item-6"}>
                <img className={"image"} src={img}/>
            </div>
        </div>)
}

export {
    SeccionLeft,
    SeccionRight
}
