import React from "react";

function Reciclaje() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="393.66 1004.775 538.756 491.907"
            xmlSpace="preserve"
        >
            <g>
                <g transform="translate(-5.236 -226.819)">
                    <g transform="matrix(.6319 0 0 .6319 -16.002 -89.951) rotate(-120 129.132 571.793)">
                        <g>
                            <path d="M-2038.265 524.731l-128.022-1.275-59.771 103.523a22.52 22.52 0 00-.991 1.931 22.602 22.602 0 00-.836 2.1 22.556 22.556 0 00-1.019 4.407 22.676 22.676 0 00-.174 2.255c-.022.755-.007 1.511.047 2.264a22.613 22.613 0 001.498 6.606c.274.702.583 1.391.926 2.062l.038.084a22.691 22.691 0 002.471 3.786 22.74 22.74 0 003.171 3.227 22.755 22.755 0 003.749 2.532c.666.356 1.35.679 2.048.968.696.291 1.406.546 2.128.766a22.672 22.672 0 006.548.968h.005l168.185 1.275-.001-137.479z"></path>
                            <path d="M-2117.451 508.621l-56.047-143.456-152.259 23.19 44.623 25.763-44.132 76.449a21.95 21.95 0 00-1.028 2.016 22.58 22.58 0 00-1.439 4.285 23.11 23.11 0 00-.555 4.486c-.018.756 0 1.515.054 2.268a23.04 23.04 0 00.795 4.447 22.344 22.344 0 001.663 4.205 22.75 22.75 0 001.134 1.962l78.196 135.44a22.55 22.55 0 01-.924-2.064 22.924 22.924 0 01-.721-2.152 22.612 22.612 0 01-.774-4.456 20.84 20.84 0 01-.045-2.263 22.47 22.47 0 01.574-4.481c.17-.735.375-1.467.619-2.181.244-.715.524-1.411.837-2.098.297-.66.628-1.303.989-1.93l59.771-103.527 24.049-41.664 44.62 25.761z"></path>
                        </g>
                        <g>
                            <path d="M-1938.624 394.046l62.008 112.011 119.535.964c.724.005 1.448-.025 2.169-.089a22.517 22.517 0 004.441-.843 22.572 22.572 0 004.182-1.71 22.57 22.57 0 001.947-1.157 22.764 22.764 0 003.493-2.87 22.662 22.662 0 001.515-1.69c.476-.585.923-1.193 1.338-1.822a3.53 3.53 0 01.055-.074 22.875 22.875 0 002.806-6.159 22.577 22.577 0 00.701-8.977 22.414 22.414 0 00-.383-2.229 22.514 22.514 0 00-1.433-4.29 22.778 22.778 0 00-.956-1.888l-81.809-146.953-119.609 67.776z"></path>
                            <path d="M-1915.368 471.236l-97.177 119.488 95.242 121.036.414-51.525 88.27.705c.755.005 1.508-.027 2.261-.099a22.659 22.659 0 004.437-.861 23.335 23.335 0 002.134-.762 22.52 22.52 0 002.042-.966 23.624 23.624 0 001.947-1.166 22.937 22.937 0 004.989-4.577c.474-.588.914-1.195 1.326-1.826.413-.632.8-1.284 1.148-1.955l79.283-134.807a22.67 22.67 0 01-1.34 1.821 22.66 22.66 0 01-3.192 3.211c-.583.48-1.192.928-1.82 1.348-.628.419-1.277.807-1.947 1.155a22.281 22.281 0 01-4.181 1.709c-.724.214-1.462.397-2.203.537-.742.14-1.486.24-2.239.306-.721.067-1.443.096-2.167.091l-119.538-.962-48.105-.382.416-51.519z"></path>
                        </g>
                        <g>
                            <path d="M-2100.926 376.681l64.087-110.834-60.728-102.964a22.656 22.656 0 00-1.193-1.813 22.433 22.433 0 00-3.01-3.373 22.67 22.67 0 00-9.873-5.293 22.762 22.762 0 00-2.229-.429 22.602 22.602 0 00-2.251-.209l-.092-.009a22.478 22.478 0 00-2.263.033 22.658 22.658 0 00-4.461.733 22.688 22.688 0 00-6.207 2.714 22.93 22.93 0 00-1.85 1.307c-.595.463-1.167.956-1.714 1.476a22.74 22.74 0 00-2.941 3.438 22.819 22.819 0 00-1.126 1.792l-83.844 145.801 119.695 67.63z"></path>
                            <path d="M-2047.496 314.523l152.469 21.772 54.71-143.971-44.381 26.177-44.85-76.03a22.072 22.072 0 00-1.249-1.887 22.599 22.599 0 00-3.023-3.361 23.196 23.196 0 00-1.753-1.437 22.486 22.486 0 00-1.88-1.253 21.881 21.881 0 00-4.097-1.921 22.767 22.767 0 00-4.397-1.067 22.6 22.6 0 00-2.248-.196 22.859 22.859 0 00-2.267.022l-156.386 1.455a22.66 22.66 0 012.251.21 22.514 22.514 0 016.488 1.949c.682.323 1.35.679 1.993 1.074.649.388 1.271.812 1.878 1.259.604.452 1.184.933 1.74 1.444.556.51 1.093 1.049 1.594 1.612.502.564.971 1.15 1.415 1.761.428.583.825 1.188 1.194 1.811l60.732 102.966 24.444 41.433-44.377 26.178z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Reciclaje;