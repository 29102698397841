export const francais = {
    home: "Home",
    about: "Qui sommes",
    weare: "Que faisons",
    clients: "Clients",
    showroom: "Showroom",
    contact: "Contact",
    copyright: "COPYRIGHT 2022 ALBEDECO. TOUS DROITS RÉSERVÉS",
    contactForm: {
        name: "Entrez votre nom",
        surname: "Entrez votre nom de famille",
        email: "Entrez votre mail",
        telf: "Entrez votre téléphone",
        subject: "Entrez le sujet",
        content: "Saisissez le contenu",
        send: "Envoyer",
        privacidad: "J'accepte la politique de confidentialité"
    },
    contactHeader: {
        title: "Comment pouvons-nous vous aider?",
        desc: "Vous pouvez nous contacter via le formulaire suivant"
    },
    compromisos: {
        conciliacion: {
            text: "Des horaires intensifs pour favoriser la conciliation travail-famille",
            title: "Conciliation"
        },
        igualdad: {
            text: "Égalité salariale entre les salariés et majorité de femmes dans les postes de direction",
            title: "Égalité"
        },
        integracion: {
            text: "Travailleurs ayant une diversité fonctionnelle dans l'effectif",
            title: "Intégration sociale"
        },
        reciclaje: {
            text: "Système de tri des déchets dans nos bureaux et entrepôts",
            title: "Recyclage"
        },
        sostenibilidad: {
            text: "Reconnaissance de nos travailleurs pour l'utilisation de transports durables",
            title: "Durabilité"
        },
    },
    homePresentation: [`Albedeco est une jeune entreprise née à Valence, en Espagne, marquée par le caractère
méditerranéen qui nous caractérise et influencée par nos voyages constants à travers
du monde. Notre vocation internationale nous caractérise.`, `Nous sommes formés par une équipe jeune de gestion et de professionnels, mais en
même temps avec une grande expérience dans le secteur, toujours orientée vers la
satisfaction de nos clients.`, `Nos produits sont fabriqués en suivant un contrôle de qualité strict, avec des designs
modernes et innovants réalisés avec les meilleurs matériaux, qui donnent à nos tissus la
qualité qui nous caractérise.`, `La qualité et la satisfaction du client sont des prémisses qui marquent notre culture de
marque.`],
    clientsPresentation: [`At Albedeco we open the borders to the international market, with representatives in the
main cities of Europe. Our clear international vocation focuses us on being present in
more and more countries and our growth objective has in mind a clearly exporting
mentality.`, `We are committed to manufacturing as much as possible in our country. We are proud
of the land to which we belong and of our roots, proud of being ‘Made in Spain’,
promoting the local and proximity economy and reducing the carbon footprint to the
minimum.`, `In turn, the great influences that we receive from our international trips and the
proposals and suggestions of our clients, make us develop designs and collections
thinking about the markets to which our articles are going to be destined. Our premise is
to offer our customers what they want and need.`, `We conceive, develop, create and manufacture products with you, our customer, in mind.
Your satisfaction is our best reward!`],
    aboutPresentation: [`Albedeco est une jeune entreprise née à Valence, en Espagne, marquée par le caractère
méditerranéen qui nous caractérise et influencée par nos voyages constants à travers
du monde. Notre vocation internationale nous caractérise.`, `Nous sommes formés par une équipe jeune de gestion et de professionnels, mais en
même temps avec une grande expérience dans le secteur, toujours orientée vers la
satisfaction de nos clients.`, `Nos produits sont fabriqués en suivant un contrôle de qualité strict, avec des designs
modernes et innovants réalisés avec les meilleurs matériaux, qui donnent à nos tissus la
qualité qui nous caractérise.`, `La qualité et la satisfaction du client sont des prémisses qui marquent notre culture de
marque.`],
    productPresentation: [`Nos produits sont créés avec amour, respect de l'environnement et qualité.`,
        `L'amour de la beauté, du design et du travail que nous faisons. Passion pour la mode,
pour les designs et les couleurs tendance. Passion pour la décoration !`,
        `Respect de la nature, de l'environnement et des personnes impliquées dans la création,
le développement et la production de nos articles.`, `La qualité, comme prémisse fondamentale de nos créations. Tous nos produits sont
fabriqués selon des paramètres de qualité stricts, des conditions de travail équitables et
une collaboration étroite avec nos fournisseurs, ce qui nous permet de donner le meilleur
de nous-mêmes, du début à la fin, à toutes les personnes impliquées dans le processus.`
    ],
    home1: {
        title: "L’IMPRESSION DIGITALE",
        text: [`Chez Albedeco, nous sommes spécialisés dans la fabrication de tissus de haute qualité
destinés principalement à la décoration et à la mode.`,
            `L'impression digitale nous permet de suivre les dernières tendances du marché en
termes de design et de couleur, ce qui nous permet d'utiliser différents matériaux et
d'offrir ainsi une multitude de tissus différents.`,
            `Notre équipe de design interne, ainsi que la collaboration de designers indépendants de
différents pays, est chargée de créer et de développer des collections uniques adaptées
à tous les goûts et à tous les marchés, en s'engageant toujours dans la voie du design et
de la durabilité.`,
            `Le pouvoir du design!`
        ]
    },
    home2: {
        title: "JACQUARDS",
        text: [`Nous avons une grande expérience dans la fabrication de tissus Jacquard exclusifs pour
des clients importants, offrant la possibilité de fabriquer des modèles exclusifs sur
demande.`,
            `Nous disposons d'une équipe de professionnels ayant une longue expérience dans la
création de tissus Jacquard qui contribuent à la recherche et au développement de
nouveaux tissus et structures, créant ainsi des collections inspirantes.`,
            `Notre maxime est de créer des articles exclusifs et de qualité qui satisfont nos clients.`
        ]
    },
    home3: {
        title: "LINGE DE MAISON ET DÉCORATION",
        text: [`Chez Albedeco, nous disposons d'une large gamme d'articles destinés à la décoration
intérieure. La maison est l'environnement dans lequel nous passons le plus d'heures de
notre vie et elle devrait donc être l'endroit où nous nous sentons le plus à l'aise. C'est
pourquoi nous sommes si soucieux de créer des produits qui rendent la décoration de
notre maison agréable. Nous aimons notre maison!`, `Chez Albedeco, nous sommes tous conscients de l'importance de la maison et de la
décoration dans nos vies et nous nous efforçons de créer des designs exclusifs de
manière responsable et efficace, en nous engageant à fabriquer des produits Made in
Spain et à offrir à nos clients les produits qu'ils méritent.`]
    },
    home4: {
        title: "TISSUS UNIS",
        text: [`Nous disposons d'une large gamme de tissus unis dans différentes qualités et finitions
et dans un large éventail de couleurs.`, `Chaque tissu et chaque couleur sont parfaitement étudiés et sélectionnés en suivant les
tendances du moment.`, `Nous nous ferons un plaisir de vous conseiller pour répondre aux besoins et aux
spécifications que vous recherchez.`]
    },
    product1: {
        title: "L’IMPRESSION DIGITALE",
        text: [`Chez Albedeco, nous sommes spécialisés dans la fabrication de tissus de haute qualité
destinés principalement à la décoration et à la mode.`,
            `L'impression digitale nous permet de suivre les dernières tendances du marché en
termes de design et de couleur, ce qui nous permet d'utiliser différents matériaux et
d'offrir ainsi une multitude de tissus différents.`,
            `Notre équipe de design interne, ainsi que la collaboration de designers indépendants de
différents pays, est chargée de créer et de développer des collections uniques adaptées
à tous les goûts et à tous les marchés, en s'engageant toujours dans la voie du design et
de la durabilité.`,
            `Le pouvoir du design!`
        ]
    },
    product4: {
        title: "TISSUS UNIS",
        text: [`Nous disposons d'une large gamme de tissus unis dans différentes qualités et finitions
et dans un large éventail de couleurs.`, `Chaque tissu et chaque couleur sont parfaitement étudiés et sélectionnés en suivant les
tendances du moment.`, `Nous nous ferons un plaisir de vous conseiller pour répondre aux besoins et aux
spécifications que vous recherchez.`]
    },
    product2: {
        title: "JACQUARDS",
        text: [`Nous avons une grande expérience dans la fabrication de tissus Jacquard exclusifs pour
des clients importants, offrant la possibilité de fabriquer des modèles exclusifs sur
demande.`,
            `Nous disposons d'une équipe de professionnels ayant une longue expérience dans la
création de tissus Jacquard qui contribuent à la recherche et au développement de
nouveaux tissus et structures, créant ainsi des collections inspirantes.`,
            `Notre maxime est de créer des articles exclusifs et de qualité qui satisfont nos clients.`
        ]
    },
    product3: {
        title: "LINGE DE MAISON ET DÉCORATION",
        text:  [`Chez Albedeco, nous disposons d'une large gamme d'articles destinés à la décoration
intérieure. La maison est l'environnement dans lequel nous passons le plus d'heures de
notre vie et elle devrait donc être l'endroit où nous nous sentons le plus à l'aise. C'est
pourquoi nous sommes si soucieux de créer des produits qui rendent la décoration de
notre maison agréable. Nous aimons notre maison!`, `Chez Albedeco, nous sommes tous conscients de l'importance de la maison et de la
décoration dans nos vies et nous nous efforçons de créer des designs exclusifs de
manière responsable et efficace, en nous engageant à fabriquer des produits Made in
Spain et à offrir à nos clients les produits qu'ils méritent.`]
    },
    mailExito: "Courrier envoyé avec succès",
    mailError: "L'e-mail n'a pas pu être envoyé",

}